export const environment = {
  production: true,
  name: 'production',
  version: '99331e4',
  auth0: {
    domain: 'homeschoolanchor.us.auth0.com',
    clientId: 'KZNJ7EnC68JRAwcUlIupDjmjkSmy8eET',
    authorizationParams: {
      audience: 'homeschoolanchor.com',
      redirect_uri: 'https://app.homeschoolanchor.com',
    },
    errorPath: '/error',
  },
  sentry: {
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    tracePropagationTargets: [/^https:\/\/app.homeschoolanchor\.com\/api/, /^\//],
  },
  chargebee: {
    site: 'homeschoolanchor',
  },
};
